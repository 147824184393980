<template>
    <update-form
        type="businessData"
        :survey-parts="$parent.surveyParts"
        :fields="fields"
    />
</template>

<script>
    import UpdateForm from "@/components/data/surveryPart/UpdateForm";

    export default {
        components: {
            UpdateForm
        },
        computed: {
            fields: function() {
                return [
                    {
                        key: 'revenue',
                        label: this.$t('Net sales'),
                        //label: 'Nettoumsatz',
                        type: 'currency',
                        unit: this.$t('Euro in the last financial year'),
                    },
                    {
                        key: 'revenueGrowthPreviousYear',
                        label: this.$t('Sales development compared to the previous year'),
                        //label: 'Umsatzentwicklung  zum Vorjahr',
                        type: 'number',
                        unit: this.$t('% in the last financial year'),
                    },
                    {
                        key: 'wageCost',
                        label: this.$t('Total wage costs'),
                        //label: 'Lohnkosten gesamt',
                        type: 'currency',
                        unit: this.$t('Euro in the last financial year'),
                    },
                    {
                        key: 'temporaryStaffCost',
                        label: this.$t('Costs of temporary workers, personnel leasing, etc.'),
                        //label: 'Kosten Aushilfen, Personalleasing, u.ä.',
                        type: 'currency',
                        unit: this.$t('Euro in the last financial year'),
                    },
                    {
                        key: 'margin',
                        label: this.$t('Margin (total)'),
                        //label: 'Marge (insgesamt)',
                        type: 'currency',
                        unit: this.$t('Euro in the last financial year'),
                    },
                ];
            }
        }
    }
</script>
